// Testnet Contract Addresses
export const AccessControlManager =
  "0x7408e72DaD483859570df578F57f4F61434a8d39";
export const TokenRegistry = "0x30E76950e5eFf7f193FD0a55d86E5249f5f51F7B";
export const ProviderRegistry = "0x1C64658c0738eB15744DDCFE0Efe442994AA4cd5";
export const ProviderAttributeRegistry =
  "0x4a356b572522eD2EF88D04A30C53AEfBdF854A88";
export const FizzRegistry = "0xedd3B167c1927bc9Fbf7F5D73F9cFE835eD8cd1e";
export const FizzAttributeRegistry =
  "0x8B4e0fE5C9293855681e518fb9B1c8B0114A0f83";
export const Escrow = "0x1377BF6D421dc2926b54a93A4bBDDb9F8E857EFc";
export const OrderRequest = "0x66286846010EE3A43D85175b04D5BA5A176Cb961";
export const ResourceManager = "0xF01fB027593DD2C60e0024AF8F9014c41fA0756e";
export const ComputeLease = "0x314C4616C6371686234DeE6244F40a4E199c7E87";
export const Bid = "0x4AF4902D5f0F5c83a40A41f561273f884854e087";
export const ResourceRegistryCPU = "0x7aEEE296044c955A707A3A0BB6E1A505bbD15585";
export const ResourceRegistryGPU = "0x01e909Ac5E5D833998bA84a1BB2a16d227A9b3ea";
export const ResourceRegistryFactory =
  "0xd6d4C265B3549c4425DF1a15CCEA0634EcB351D2";
export const ProviderRewardsManager =
  "0x1b932690a36966C56e89103b786E0bb01bDDDc36";
export const FizzRewardsManager = "0x9dE3eB40b66F07e92740328e67fF4762d4D0147b";
export const RewardToken = "0xa8ACde36Ec65A5ca286CE5617f0b22F26341B8aE";
export const FizzNodeRewardToken = "0xD77bE14f52F4d5Be75C660f5cCDDB98edd889143";

// Testnet Token Addresses
export const USDT = "0x01e65Ad8F4824037Ed8b3111A6c232D1b8458Bea";
export const USDC = "0xbb132eB1b36D87ef7Ac63c95c05a8a56eCDac3fd";
export const DAI = "0x1fFC5C93E3037aa387D3A988e9aCF2Cd11C8B782";
export const WETH = "0xcB66efD46915F97Dd97951a98941ca8d02642aaa";
export const CST = "0xa76cf27b51eb93c417cce78af5cf0a3e2d9aa55c";
