import USDTIcon from "@spheron/ui-library/dist/assets/crypto-social/Tether.svg?url";
import USDCIcon from "@spheron/ui-library/dist/assets/crypto-social/Usdc.svg?url";
import DaiIcon from "@spheron/ui-library/dist/assets/crypto-social/Dai.svg?url";
import WethIcon from "@spheron/ui-library/dist/assets/crypto-social/Weth.svg?url";
import { CST, DAI, USDC, USDT, WETH } from "@/contracts/addresses";

// export const SPHERON_RPC_URL = "https://spheron-devnet.rpc.caldera.xyz/http";
// export const SPHERON_RPC_URL = "https://sepolia-rollup.arbitrum.io/rpc";
// export const SPHERON_RPC_URL = "https://arbitrum-sepolia.core.chainstack.com/f89a40155b4b82dc5c601286fcac4ffb";
// export const SPHERON_TESTNET_EXPLORER_URL = "https://sepolia.arbiscan.io/";
export const SPHERON_RPC_URL =
  "https://spheron-devnet-eth.rpc.caldera.xyz/infra-partner-http";
export const SPHERON_DEVNET_HTTP_URL =
  "https://spheron-devnet-eth.rpc.caldera.xyz/infra-partner-http";
export const SPHERON_TESTNET_EXPLORER_URL =
  "https://spheron-devnet-eth.explorer.caldera.xyz";
export const SPHERON_DEVNET_EXPLORER_URL =
  "https://spheron-devnet.explorer.caldera.xyz/";
export const SAMPLE_PROVIDER_ADDRESS =
  "0x6634d41cccBD1E1576Ed4c6226832521A66bF874"; // us-east provider
// export const SAMPLE_PROVIDER_ADDRESS =
//   "0x562937835cdD5C92F54B94Df658Fd3b50A68ecD5";
// export const SAMPLE_PROVIDER_ADDRESS =
//   "0x74bb7e5058Fa6FCE9928FAC9A285377E5dFD1680";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://provider.spheron.network"
    : "http://localhost:3010";
export const SERVICE_API_URL = "https://api-v2.spheron.network";

export interface IToken {
  id: number;
  name: string;
  symbol: string;
  decimal: number;
  address: string;
  logo: any;
}

export interface INetwork {
  chainId: number;
  chainName: string;
  rpcUrls: string[];
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExplorerUrls: string[];
}

export type NetworkType = "testnet" | "mainnet";

export const networkMap: Record<NetworkType, INetwork> = {
  // testnet: {
  //   chainId: 421614,
  //   chainName: "Arbitrum Sepolia",
  //   rpcUrls: ["https://sepolia-rollup.arbitrum.io/rpc"],
  //   nativeCurrency: {
  //     name: "Ethereum",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   blockExplorerUrls: ["https://sepolia.arbiscan.io/"],
  // },
  testnet: {
    chainId: 89138,
    chainName: "Spheron Devnet",
    rpcUrls: ["https://spheron-devnet-eth.rpc.caldera.xyz/http"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://spheron-devnet-eth.explorer.caldera.xyz/"],
  },
  // TODO: NEED TO UPDATE WHEN MAINNET RELEASE
  mainnet: {
    chainId: 421614,
    chainName: "Arbitrum Sepolia",
    rpcUrls: ["https://sepolia-rollup.arbitrum.io/rpc"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://sepolia.arbiscan.io/"],
  },
};

export const tokenMap: Record<NetworkType, IToken[]> = {
  testnet: [
    {
      id: 1,
      name: "Tether USD Test Token",
      symbol: "USDT",
      decimal: 6,
      address: USDT,
      logo: USDTIcon,
    },
    {
      id: 2,
      name: "DAI Test Token",
      symbol: "DAI",
      decimal: 18,
      address: DAI,
      logo: DaiIcon,
    },
    {
      id: 3,
      name: "Test Token USD Coin",
      symbol: "USDC",
      decimal: 6,
      address: USDC,
      logo: USDCIcon,
    },
    {
      id: 4,
      name: "Wrapped ETH Test Token",
      symbol: "WETH",
      decimal: 18,
      address: WETH,
      logo: WethIcon,
    },
    {
      id: 5,
      name: "Spheron Credit Token",
      symbol: "CST",
      decimal: 18,
      address: CST,
      logo: "",
    },
  ],
  mainnet: [],
};

export const networkType =
  (process.env.NEXT_PUBLIC_NETWORK_TYPE as string) || "testnet";
